
.App{
  background-color: #f8de88;
}

.App-body{
  background-color: #f8de88;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(2, 2, 2);
}
img {
  max-width: 100%;
  height:700px;
}



.waterdrop{
  height:50px;
  width: 50px;
  display:flex;
  top:0px;
  left:30px;
  position:absolute;
  background-image: url(waterdrop.png);
  background-size: 50px 50px;
  animation-name: drop;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  
}



@keyframes drop{
  from{
    transform: translateY(0);
    opacity:1;
  }
  to{
    transform: translateY(100px);
    opacity:.1;
  }
}


